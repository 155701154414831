<template>
  <CContainer class="d-flex align-items-center min-vh-100">
    <CRow class="w-100 justify-content-center">
      <CCol md="6">
        <div class="w-100">
          <div class="clearfix">
            <h1 class="float-left display-3 mr-4">Bye!</h1>
            <h4 class="pt-3">You have logout your account</h4>
            <CButton color="info" @click="$router.push('/auth/login')">Go Back To Login Page</CButton>
          </div>
        </div>
      </CCol>
    </CRow>
  </CContainer>
</template>

<script>
export default {
  name: 'Logout',
  mounted: function () {
    this.onLoadPage();
  },
  methods: {
    onLoadPage: function () {
      this.$axios.get("auth/logout", {})
        .then((response) => {
          if (response.data.code === 200) {
            setTimeout( () => this.$router.push({ path: '/auth/login'}), 3000);
          }
        });
    }
  }
}
</script>